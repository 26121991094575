import React from 'react';
import Layout from '../components/layout/Layout';
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Amplify from 'aws-amplify'
import awsmobile from '../aws-exports'

const Shop = ({ data }) => {

return(
	  <Layout>		
	  	<section className="pt-10 md:pt-20">
	  		<div className="container mx-auto px-8 lg:flex">
	  			<div className="text-center lg:text-left lg:w-1/2">
	  				<h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
	  					Steunverkoop
	  				</h1>
	  				
	  				
	  				
	  				
	  				
	  				
	  				
<div id="fh5co-product">
	<div class="container">
		<div class="row animate-box fadeInUp animated-fast">
			<div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
				<h2>Dranken</h2>
							
			</div>
		</div>								
		<div class="row">
			{data.Products.edges.map(({ node }, index) => (							
				<div class="col-md-2 text-center animate-box fadeInUp animated-fast">
					<div class="product">					
						<div class="inner">
								<p>
									<button
									id={node.id}
									class="icon Product-button snipcart-add-item"
									data-item-id={node.id}
									data-item-price={node.field_price}
									data-item-name={node.title}
									data-item-url={`/`}
									data-item-custom1-name="CustomerProfitPercentage"
									data-item-custom1-type="hidden"
									data-item-custom1-value={node.field_custprofitpct}
									>
									<i class="icon-shopping-cart"></i></button>			
								</p>
						</div>
					<div class="desc">
						<h3><Link to={`${node.path.alias}`}>{node.title}</Link></h3>
						<span class="price">{node.field_price} Euro</span>	
							<div dangerouslySetInnerHTML={{ __html: node.body.summary.split(' ').splice(0, 50).join(' ') + '...' }}></div>
								<p>
								<Link to={`${node.path.alias}`}><a href={`${node.path.alias}`} class="icon"> Meer Info </a></Link>
								</p>
							</div>
								<p>		
									<a
									href="/"
									class="icon Product-button snipcart-add-item"
									data-item-id={node.id}
									data-item-price={node.field_price}
									data-item-name={node.title}
									data-item-url={`/`}
									data-item-custom1-name="CustomerProfitPercentage"
									data-item-custom1-type="hidden"
									data-item-custom1-value={node.field_custprofitpct}
									>
									<i class="icon-shopping-cart"></i>  Add to Cart</a>	
								</p>
					</div>
				</div>
			))}
		</div>						
		</div>
		</div>
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  				
	  			</div>
	  		</div>
	  	</section>
      </Layout>
      )
}

export default Shop

export const query = graphql`
query productsQuery {
 file(name: { eq: "logo" }, extension: { eq: "png" }) {
        childImageSharp {
	      gatsbyImageData(width: 100)  
        }
      }	
site {
    siteMetadata {
      title
    }
  }
  Products:allNodeWebshopProduct(filter: {relationships: {group_content__group_content_type_eaf1e027bc183: {elemMatch: {drupal_id: {}, path: {langcode: {}}, relationships: {gid: {drupal_internal__id: {in:[7,8,5] }}}}}}}) {
    edges {
      node {
        title
        id
        created(formatString: "DD MMMM YYYY")
        field_price
        field_custprofitpct
        body {
          summary
        }
        path {
          alias
        }
        relationships {
          field_image_carrousel {
            localFile {
              childImageSharp {
	            gatsbyImageData(width: 800)
                fluid(maxWidth: 800, quality: 100) {
	              ...GatsbyImageSharpFluid
                  src
                }
              }
            }
          }
        }
      }
    }
  }
  Events:allNodeEvent(filter: {relationships: {group_content__closed_group_group_node_event: {elemMatch: {relationships: {gid: {drupal_internal__id: {in: [7]}}}}}}}) {
    edges {
      node {
        title
        id
        path {
          alias
        }
        relationships {
          field_event_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
	              ...GatsbyImageSharpFluid  
                  src
                }
              }
            }
          }
        }
      }
    }
  }
}
`